<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="sessionData === undefined">
      <h4 class="alert-heading">Error fetching session data</h4>
      <div class="alert-body">
        No session found with this session id. Check
        <b-link class="alert-link" :to="{ name: 'apps-roster-session-list' }"> Session List </b-link>
        for other sessions.
      </div>
    </b-alert>

    <template v-if="sessionData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="3" lg="3" md="12">
          <session-view-session-info-card :session-data="sessionData" @refresh="refetchStudents" />
        </b-col>
        <b-col cols="12" md="12" xl="9" lg="9">
          <b-card no-body class="mb-0">
            <div class="m-2">
              <!-- Table Top -->
              <b-row>
                <!-- Per Page -->
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                  <label>Show</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                  />
                  <label>entries</label>
                </b-col>

                <!-- Search -->
                <b-col cols="12" md="6">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button variant="primary" @click="startCourseSelect">
                      <span class="text-nowrap">Add Course</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-table
              ref="refSessionListTable"
              class="position-relative"
              :items="fetchSessions"
              :fields="tableColumns"
              responsive
              primary-key="id"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              show-empty
              empty-text="No matching records found"
            >
              <!-- Column: Session -->
              <template #cell(name)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="32"
                      :src="data.item.avatar"
                      :text="avatarText(data.item.name)"
                      :variant="`light-${resolveCourseStatusVariant(data.item.status)}`"
                      :to="{ name: 'apps-roster-course-view', params: { id: data.item.id } }"
                    />
                  </template>
                  <b-link
                    :to="{ name: 'apps-roster-course-view', params: { id: data.item.id } }"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ data.item.name }}
                  </b-link>
                  <small class="text-muted">{{ (data.item.classes || []).length }} total classes</small>
                </b-media>
              </template>

              <!-- Column: Live Courses -->
              <template #cell(live_courses)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{
                    data.item.courses.filter((course) => course.status === 'live').length
                  }}</span>
                </div>
              </template>
              <!-- Column: Complete Courses -->
              <template #cell(completedCourses)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{
                    data.item.courses.filter((courses) => courses.status === 'completed').length
                  }}</span>
                </div>
              </template>
              <!-- Column: Total Students -->
              <template #cell(profiles)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">
                    {{ data.item.profiles.length }}
                  </span>
                </div>
              </template>

              <!-- Column: Visibility -->
              <template #cell(visibility)="data">
                <b-badge
                  pill
                  :variant="`light-${resolveSessionVisibilityVariant(data.item.visibility)}`"
                  class="text-capitalize"
                >
                  {{ (data.item.visibility || '').split('_').join(' ') }}
                </b-badge>
              </template>

              <!-- Column: Status -->
              <template #cell(status)="data">
                <b-badge pill :variant="`light-${resolveSessionStatusVariant(data.item.status)}`" class="text-capitalize">
                  {{ data.item.status.split('_').join(' ') }}
                </b-badge>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>
                  <b-dropdown-item :to="{ name: 'apps-roster-course-view', params: { id: data.item.id } }">
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">View</span>
                  </b-dropdown-item>

                  <b-dropdown-item :to="{ name: 'apps-roster-course-edit', params: { id: data.item.id } }">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item>
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Remove</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted"
                    >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
                  >
                </b-col>
                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalSessions"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12">
          <session-view-session-permissions-card
            class="mt-2"
            :session-data.sync="sessionData"
            :session-id.sync="sessionId"
            @refresh="refetchStudents"
          />
        </b-col>
      </b-row>
    </template>

    <session-add-course :is-add-course-open.sync="isAddCourseOpen" @refresh="refetchStudents" :session="sessionData" />
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText, title } from '@core/utils/filter';

import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTable,
  BFormInput,
  BButton,
  BCard,
  BMedia,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownDivider,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue';
import rosterStoreModule from '../rosterStoreModule';
import SessionViewSessionInfoCard from './SessionViewUserInfoCard';
import SessionViewSessionPlanCard from './SessionViewUserPlanCard.vue';
import SessionViewSessionTimelineCard from './SessionViewUserTimelineCard.vue';
import SessionViewSessionPermissionsCard from './SessionViewUserPermissionsCard.vue';
import SessionList from '@/views/apps/roster/sessions-list/SessionList';
import useSessionSessionsList from './useSessionCoursesList';
import moment from 'moment';
import SessionAddCourse from '@/views/apps/roster/sessions-view/components/SessionAddCourse';
import receivableStoreModule from '@/views/apps/accounting/receivable/receivableStoreModule';

export default {
  components: {
    SessionAddCourse,
    SessionList,
    BRow,
    BCol,
    BAlert,
    BLink,
    BTable,

    BCard,
    BFormInput,
    BButton,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownDivider,

    vSelect,

    // Local Components
    SessionViewSessionInfoCard,
    SessionViewSessionPlanCard,
    SessionViewSessionTimelineCard,
    SessionViewSessionPermissionsCard,

    InvoiceList,
  },
  data: () => {
    return {
      isAddCourseOpen: false,
    };
  },
  computed: {
    sessionId() {
      return this.$route.params.id;
    },
  },
  methods: {
    startCourseSelect() {
      this.isAddCourseOpen = true;
    },
    refetchStudents() {
      this.$store
        .dispatch('app-roster/fetchSessionSummary', {
          id: this.$route.params.id,
        })
        .then((response) => {
          this.sessionData = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            this.sessionData.value = undefined;
          }

          console.error(error);
        });
    },
  },
  mounted() {
    this.refetchStudents();
  },
  setup() {
    const sessionData = ref({ profiles: [], courses: [], metadata: {} });

    const SESSION_APP_STORE_MODULE_NAME = 'app-roster';
    const RECEIVABLE_APP_STORE_MODULE_NAME = 'app-accounting-receivable';

    // Register module
    if (!store.hasModule(SESSION_APP_STORE_MODULE_NAME))
      store.registerModule(SESSION_APP_STORE_MODULE_NAME, rosterStoreModule);
    // Register module
    if (!store.hasModule(RECEIVABLE_APP_STORE_MODULE_NAME))
      store.registerModule(RECEIVABLE_APP_STORE_MODULE_NAME, receivableStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SESSION_APP_STORE_MODULE_NAME)) store.unregisterModule(SESSION_APP_STORE_MODULE_NAME);
      if (store.hasModule(RECEIVABLE_APP_STORE_MODULE_NAME)) store.unregisterModule(RECEIVABLE_APP_STORE_MODULE_NAME);
    });

    const {
      fetchSessions,
      tableColumns,
      perPage,
      currentPage,
      totalSessions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSessionListTable,
      refetchData,

      statusOptions,

      // UI
      resolveSessionVisibilityVariant,
      resolveSessionStatusVariant,
      resolveCourseStatusVariant,

      // Extra Filters
      visibilityFilter,
      statusFilter,
    } = useSessionSessionsList(sessionData);

    return {
      sessionData,

      fetchSessions,
      tableColumns,
      perPage,
      currentPage,
      totalSessions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSessionListTable,
      refetchData,

      statusOptions,

      // UI
      resolveSessionVisibilityVariant,
      resolveSessionStatusVariant,
      resolveCourseStatusVariant,

      // Extra Filters
      visibilityFilter,
      statusFilter,

      //
      moment,
      avatarText,
      title,
    };
  },
};
</script>

<style></style>
